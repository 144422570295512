<template>
    <div v-if="showModal">
        <div class="special-overlay"></div>
        <div class="modal" style="display: block;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div style="float: right;">
                        <button type="button" @click="closeModal" class="close text-right mr-4 mt-3" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" style="font-size: 20px;">&times;</span>
                        </button>
                    </div>
                    <!-- <h2 class="modern text-center mb-0">Menu Schedule<br>Changes!<br></h2> -->
                    <h2 class="modern text-center mb-0">Join us for brunch<br>on New Year's Day!<br></h2>
                    <div class="modal-body">
                        <div class="row border-top">
                            <div class="col-lg-1"></div>
                            <div class="col-lg-10 mt-4">

                                <h5 class="text-center mt-3a">
                                    Available on 1/1 from 11am-3pm. 
                                    <br>
                                    <a target="_blank" href="https://www.opentable.com/r/kosmos-reservations-walpole?restref=1239994&lang=en-US&ot_source=Restaurant%20website">Make your reservation today!</a>
                                    <!-- We are excited to announce that we will be open for dinner on Tuedays!  -->
                                    <br>
                                    <!-- Also, you can now enjoy our new lunch menu 12pm-3pm on Saturday & Sunday! -->
                                    <!-- <br><br> -->
                                    <!-- <b>See you all there!</b><br> -->
                                </h5>
                            </div>
                            <div class="col-lg-1"></div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="closeModal">Got it!</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SpecialOverlay',
        data: function() {
            return {
                showModal: true
            }
        },
        methods: {
            closeModal() {
                this.showModal = false;
            }
        }
    }
</script>